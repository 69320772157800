import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axiosInstance from "../axiosInstance/axiosInstance";


export default function Card() {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  // console.log(title);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post(
        "/form/add",
        {
          title: title,
          url: url,
        }
      );
      console.log(response);
      setTitle("");
      setUrl("");
      toast.success("Job is added")
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col w-[70vw] ">
      <div className="flex flex-col gap-2 ml-2 mt-2 ">
        <label
          id="title"
          className="relative top-[18px] left-4 z-10 w-fit bg-white font-semibold"
        >
          Add Title
        </label>
        <input
          placeholder="Job Title"
          type="text"
          name="title"
          className="input"
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2 ml-2 mt-2 ">
        <label
          id="title"
          className="relative top-[18px] left-4 z-10 w-fit bg-white font-semibold"
        >
          Add URL
        </label>
        <input
          placeholder="Job URL"
          type="text"
          name="text"
          className="input"
          required
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-2 ml-2 mt-2 mb-3">
        <button onClick={handleSubmit}>Add</button>
      </div>
    </div>
  );
}

// export default Card;
