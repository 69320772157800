import { useEffect, useState } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import axiosInstance from "../axiosInstance/axiosInstance";
import { Link } from "react-router-dom";
import { MdDeleteSweep } from "react-icons/md";

export default function Table() {
  const [jobsLink, setJobLink] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(7);
  // console.log(start);
  // console.log(end);

  const paginatedJobs = jobsLink.slice(start, end);
  // console.log(paginatedJobs);

  const handleNext = () => {
    if (end < jobsLink.length) {
      setStart(end);
      setEnd(end + 7);
    }
  };

  const handlePrevious = () => {
    if (start > 0) {
      setEnd(start);
      setStart(start - 7);
    }
  };

  const fatchData = async (req, res) => {
    try {
      const response = await axiosInstance.get("/form/get");
      setJobLink(response?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fatchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/form/delete/${id}`);
      // console.log(response);
      toast.success("Job link deleted");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      {/* <Header/> */}

      <div className="flex w-full flex-col">
        <div className=" px-3 mb-6 mx-auto">
          <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
            <div className="relative flex flex-col min-w-0 break-words border border-dashed bg-clip-border rounded-2xl border-stone-200 bg-light/30">
              {/* card body  */}
              <div className="flex-auto block py-8 pt-6 px-9">
                <div >
                  <table className="w-full my-0 align-middle text-dark border-neutral-200">
                    <thead className="align-bottom">
                      <tr className="font-semibold text-[0.95rem] text-secondary-dark">
                        <th className="pb-3 text-start min-w-[175px]">S.NO.</th>
                        <th className="pb-3 text-start min-w-[150px]">TITLE</th>
                        {/* <th className="pb-3 text-start min-w-[100px]">URL</th> */}
                        <th className="pb-3 pr-12 text-start min-w-[200px]">
                          DATE
                        </th>
                        <th className="pb-3 text-end min-w-[50px]">DELETE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedJobs.map((person, index) => (
                        <tr
                          key={index}
                          className="border-b border-dashed last:border-b-0"
                        >
                          <td className="p-3 pr-0">
                            <span className="font-semibold text-light-inverse text-md/normal">
                              {index + 1 + start}
                            </span>
                          </td>
                          <td className="p-3 pl-0">
                            <div className="flex hover:text-blue-500 items-center">
                              <div className="flex flex-col justify-start">
                                <Link target="_blank" to={person.url}>
                                  {person.title}
                                </Link>
                              </div>
                            </div>
                          </td>
                          {/* <td className="p-3 pr-0 text-end">
                            <span className="font-semibold text-light-inverse text-md/normal">
                              {person.url}
                            </span>
                          </td> */}
                          <td className="pr-0 text-start">
                            <span className="font-semibold text-light-inverse text-md/normal">
                              {moment(`${person.createdAt}`).format(
                                "Do MMMM YYYY"
                              )}
                            </span>
                          </td>
                          <td className="p-3 pr-0 text-end">
                            <button
                              onClick={() => {
                                handleDelete(person._id);
                              }}
                              className="ml-auto relative text-secondary-dark bg-light-dark hover:text-primary flex items-center h-[25px] w-[25px] text-base font-medium leading-normal text-center align-middle cursor-pointer rounded-2xl transition-colors duration-200 ease-in-out shadow-none border-0 justify-center"
                            >
                              <span className="flex items-center justify-center p-0 m-0 leading-none shrink-0 ">
                                <MdDeleteSweep />
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex p-5 w-full justify-evenly">
          <button
            onClick={handlePrevious}
            disabled={start === 0}
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            disabled={end >= jobsLink.length}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
