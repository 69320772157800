import React from 'react'
import LoginForm from '../components/LoginForm'

const Login = () => {
  return (
    <>
      <LoginForm/>
    </>
  )
}

export default Login
