import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const BlogForm = () => {
  const [keyPoints, setKeyPoints] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleKeyPoints = () => {
    const points = document.getElementById("key_point");
    const pointDes = document.getElementById("key_Description");
    setKeyPoints([
      ...keyPoints,
      { point: points.value, description: pointDes.value },
    ]);
    points.value = "";
    pointDes.value = "";
  };

  const addNewEnquiry = async (data) => {
    // try {
    //   const addForm = await axiosInstance.post("/excel/add",data);
    //   alert("new enquiry data added");
    //   reset();
    // } catch (error) {
    //   alert(error.message);
    // }
  };

  const handleDelete = (index) => {
    let data = keyPoints.filter((aa,idx)=>idx !== index)
    // let key = keyPoints.splice(index, 1);
    setKeyPoints(data)
  };



  return (
    <form
      className="my-8 grid gap-y-6 grid-cols-3 mx-10"
      onSubmit={handleSubmit(addNewEnquiry)}
    >
      <div className="px-3 col-span-3">
        <label
          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
          htmlFor="grid-number"
        >
          Blog Thumbnail
        </label>
        <label
          class="flex  cursor-pointer appearance-none justify-center rounded-md border border-dashed border-gray-300 bg-white px-3 py-6 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
          tabindex="0"
        >
          <span for="photo-dropbox" class="flex items-center space-x-2">
            <span class="text-xs font-medium text-gray-600">
              Drop files to Attach, or
              <span class="text-blue-600 underline">browse</span>
            </span>
          </span>
          <input id="photo-dropbox" type="file" class="sr-only" />
        </label>
      </div>
      <div className="px-3 mb-6 col-span-1 md:mb-0">
        <label
          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
          htmlFor="grid-firstname"
        >
          Blog Title
        </label>
        <input
          className="appearance-none block w-full bg-white text-black border-black border border-red rounded py-3 px-4 mb-3"
          {...register("Blog_title", { required: "Blog Title is required!" })}
          id="grid-fullname"
          type="text"
          placeholder="Enter Blog Password"
        />
      </div>
      <div className="px-3">
        <label
          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
          htmlFor="grid-last-name"
        >
          MAIN TITLE
        </label>
        <input
          className="appearance-none block w-full bg-white text-black border-black border border-grey-lighter rounded py-3 px-4"
          id="grid-last-name"
          {...register("main_title")}
          type="tel"
          placeholder="Enter contact number"
        />
      </div>

      <div className="md:w-full px-3">
        <label
          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
          htmlFor="grid-class"
        >
          Blogs Descriptions
        </label>
        <input
          {...register("description", {
            required: "Blogs Description is required",
          })}
          id="grid-class"
          className="appearance-none block w-full  bg-white text-black border-black border border-grey-lighter rounded py-3 px-4 mb-3"
        />
      </div>
      <div className="md:w-full px-3">
        <label
          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
          htmlFor="key_point"
        >
          Key point
        </label>
        <input
          id="key_point"
          className="appearance-none block w-full  bg-white text-black border-black border border-grey-lighter rounded py-3 px-4 mb-3"
        />
      </div>
      <div className="md:w-full px-3">
        <label
          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
          htmlFor="key_Description"
        >
          key Description
        </label>
        <input
          id="key_Description"
          className="appearance-none block w-full  bg-white text-black border-black border border-grey-lighter rounded py-3 px-4 mb-3"
        />
      </div>
      <div
        onClick={() => {
          handleKeyPoints();
        }}
        className="w-fit px-4 py-2 bg-slate-900 rounded-xl text-white font-bold h-fit my-auto"
      >
        Add Point
      </div>

      {keyPoints.length > 0 && (
        <div className="w-full col-span-3 border my-8 mx-3">
          <table className="border w-full border-collapse">
            <thead>
              <tr>
                <th className="border w-[10%] text-center">Sr.no</th>
                <th className="border w-1/4 text-center">Title</th>
                <th className="border text-center">Description</th>
                <th className="border text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {keyPoints.map((point, index) => (
                <tr>
                  <td className="border text-center">{index + 1}</td>
                  <td className="border text-center">{point.point}</td>
                  <td className="border text-center">{point.description}</td>
                  <td
                    className="border text-center cursor-pointer text-red-700 font-bold"
                    onClick={() => {
                      handleDelete(index);
                    }}
                  >
                    Delete
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <button>
        Add Enquiry
      </button>
    </form>
  );
};

export default BlogForm;
