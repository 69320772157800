import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { sidebarRoutes } from "../sidebarRoutes/SidebarRoutes";

function SideBar() {
  const location = useLocation();
  // const Navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem("user");
    localStorage.removeItem("token");
    // Navigate("/login");
    window.location.href = "/login";
  };

  // Get userRole
  const getUser = JSON.parse(sessionStorage.getItem("user"));
  const userRole = getUser.obj.role;

  const menuItems = sidebarRoutes[userRole] || [];

  return (
    <div
      className={
        openMenu
          ? "flex h-[100vh]  w-[20%] duration-200 flex-col justify-between border-e bg-[#05314B] overflow-hidden"
          : "flex h-[100vh]  duration-200 w-[6%] flex-col justify-between border-e bg-[#05314B] overflow-hidden"
      }
    >
      <div className="px-4 py-6">
        <i
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
          className="text-2xl text-white font-bold"
        >
          {" "}
          <IoMenu />{" "}
        </i>

        {/* <span className="grid h-10 w-32 place-content-center rounded-lg bg-[] text-xs text-gray-600">
          <img src="" alt="" srcset="" />
        </span> */}
        {openMenu && (
          //  <ul className="mt-6 space-y-1">
          //     <li>
          //       <Link
          //         to="/"
          //         className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
          //           location.pathname === "/" ? "bg-[#0FCFFF]" : ""
          //         }`}
          //       >
          //         Dashboard
          //       </Link>
          //     </li>

          //     <li>
          //       <Link
          //         to="/job"
          //         className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
          //           location.pathname === "/job" ? "bg-[#0FCFFF]" : ""
          //         }`}
          //       >
          //         Job Post
          //       </Link>
          //     </li>
          //     <li>
          //       <Link
          //         to="/enquiry"
          //         className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
          //           location.pathname === "/enquiry" ? "bg-[#0FCFFF]" : ""
          //         }`}
          //       >
          //         Enquiry
          //       </Link>
          //     </li>
          //     <li>
          //       <Link
          //         to="/salary-slip"
          //         className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
          //           location.pathname === "/salary-slip" ? "bg-[#0FCFFF]" : ""
          //         }`}
          //       >
          //         Saliry slip
          //       </Link>
          //     </li>
          //    {userRole === "Digital-team" && <li>
          //       <Link
          //         to="/blogs"
          //         className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
          //           location.pathname === "/blogs" ? "bg-[#0FCFFF]" : ""
          //         }`}
          //       >
          //         Blogs
          //       </Link>
          //     </li>}
          //   </ul>
          <ul className="mt-6 space-y-1">
            {menuItems.map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`block rounded-lg px-4 py-2 text-sm font-medium text-white ${
                    location.pathname === item.path ? "bg-[#0FCFFF]" : ""
                  }`}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
      {openMenu && (
        <div className="sticky inset-x-0 bottom-6 border-t border-#0FCFFF">
          <Link
            to="/login"
            onClick={handleLogout}
            className={`block rounded-lg px-4 py-2 text-sm font-medium text-red-300 ${
              location.pathname === "/login" ? "bg-[#0FCFFF]" : ""
            }`}
          >
            Logout
          </Link>
        </div>
      )}
    </div>
  );
}

export default SideBar;
